import { all, put, takeEvery, call } from 'redux-saga/effects';
import * as t from '../action_types';
import * as actions from '../action_creators';
import { post_api, get_api, delete_api } from '../api_funcs';
import urls from '../../urls';

export function* loadDashboardSessions() {
  try {
    const url = urls.teacher_sessions_list;
    const result = yield call(post_api, url, null, true);
    const action_payload = {
      key: 'sessions',
      payload: result.data.result
    };
    yield put(actions.set_dashboard_variable(action_payload));
    yield put(
      actions.set_dashboard_variable({
        key: 'sessions_in_next_24_hours',
        payload: result.data.sessions_in_next_24_hours
      })
    );
    yield put(
      actions.set_dashboard_variable({
        key: 'loadMoreSessions',
        payload: result.data.total_session_scheduled_msg
      })
    );
    yield put(
      actions.set_dashboard_variable({
        key: 'availability_in_next_7_days',
        payload: result.data.availability_in_next_7_days
      })
    );
    yield put(
      actions.set_dashboard_variable({
        key: 'dashboard_loading',
        payload: false
      })
    );
  } catch (error) {
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      }
    } catch (e) {}
    // console.log("in catch");
  }
}

export function* withdrawReplacementRequest(action) {
  try {
    const url = urls.withdrawReplacementRequestV2.replace(
      ':SESSION_UUID',
      action.payload.sessionUUID
    );
    const result = yield call(delete_api, url, true);

    yield put(actions.load_short_dashboard_sessions());
  } catch (error) {
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      }
    } catch (e) {}
    // console.log("in catch");
  }
}

export function* loadShortDashboardSessions() {
  try {
    const url = urls.sessions_list_v2;
    // const url = 'http://prakhar.myyogateacher.com:14000/v2/teacher_replacement/sessions/list';
    yield put(actions.set_home_variable('session_list_status', 'loading'));
    const result = yield call(get_api, url, true);
    result.data.result.sort((a, b) => {
      if (a.epoch_start_time < b.epoch_start_time) {
        return -1;
      }
      return 1;
    });
    const action_payload = {
      key: 'sessions',
      payload: result.data.result
    };
    yield put(actions.set_home_variable('session_list_status', 'success'));
    yield put(actions.set_dashboard_variable(action_payload));
    yield put(
      actions.set_dashboard_variable({
        key: 'sessions_in_next_24_hours',
        payload: result.data.sessions_in_next_24_hours
      })
    );
    yield put(
      actions.set_dashboard_variable({
        key: 'loadMoreSessions',
        payload: result.data.total_session_scheduled_msg
      })
    );
    yield put(
      actions.set_dashboard_variable({
        key: 'availability_in_next_7_days',
        payload: result.data.availability_in_next_7_days
      })
    );
    yield put(
      actions.set_dashboard_variable({
        key: 'dashboard_loading',
        payload: false
      })
    );
  } catch (error) {
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      } else {
        yield put(
          actions.set_dashboard_variable({
            key: 'dashboard_loading',
            payload: false
          })
        );
      }

      const error_msg = error.response.data.reason;
      yield put(
        actions.set_dashboard_variable({
          key: 'account_status_message',
          payload: error_msg
        })
      );
    } catch (e) {}
    // console.log("in catch");
  }
}

export function* cancelSession(action) {
  try {
    yield put(actions.set_home_variable('cancel_session_status', 'loading'));

    const payload = {
      ...action.payload
    };

    const cancel_sessions = urls.cancel_session;
    const response = yield call(post_api, cancel_sessions, payload, true);
    yield put(actions.set_home_variable('cancel_session_response', response.data));
    yield put(actions.set_home_variable('cancel_session_status', 'success'));
    yield put(actions.load_short_dashboard_sessions());
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_home_variable('cancel_session_error', error.response.data));
    yield put(actions.set_home_variable('cancel_session_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* requestReplacement(action) {
  try {
    const url = urls.request_replacement_v2;
    yield put(actions.set_home_variable('request_replacement_status', 'loading'));
    yield call(post_api, url, action.payload, true);
    yield put(actions.load_short_dashboard_sessions());
    yield put(actions.set_home_variable('request_replacement_status', 'success'));
  } catch (e) {
    try {
      yield put(actions.set_home_variable('request_replacement_status', 'failure'));
      if (e.response.status === 401) {
        yield put(actions.log_out());
      }
    } catch (err) {}
    let err_message;
    try {
      err_message = e.response.data.message;
    } catch (err) {
      err_message = 'Some error occured. Please contact care team.';
    }
    yield put(actions.set_notification_variable('error', true, err_message));
  }
}

export function* loadStudentDetails(action) {
  try {
    yield put(
      actions.set_dashboard_variable({
        key: 'student_loading_status',
        payload: 'loading'
      })
    );
    const url = urls.student_details;
    const response = yield call(post_api, url, action.payload, true);
    // yield put(actions.load_dashboard_sessions());
    yield put(
      actions.set_dashboard_variable({
        key: 'student_details',
        payload: response.data.details
      })
    );
    yield put(
      actions.set_dashboard_variable({
        key: 'student_loading_status',
        payload: 'success'
      })
    );
  } catch (error) {
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      }
    } catch (e) {}
    yield put(
      actions.set_dashboard_variable({
        key: 'student_loading_status',
        payload: 'failure'
      })
    );
  }
}

export function* loadStudentDetailsSlug(action) {
  try {
    yield put(
      actions.set_dashboard_variable({
        key: 'student_loading_status',
        payload: 'loading'
      })
    );
    const url = urls.student_details_slug;
    const response = yield call(post_api, url, action.payload, true);
    // yield put(actions.load_dashboard_sessions());
    yield put(
      actions.set_dashboard_variable({
        key: 'student_details',
        payload: response.data.details
      })
    );
    yield put(
      actions.set_dashboard_variable({
        key: 'student_loading_status',
        payload: 'success'
      })
    );
  } catch (error) {
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      }
    } catch (e) {}
    yield put(
      actions.set_dashboard_variable({
        key: 'student_loading_status',
        payload: 'failure'
      })
    );
  }
}

export function* getSessionUpdateSlots(action) {
  try {
    yield put(
      actions.set_dashboard_variable({
        key: 'get_session_update_slots_status',
        payload: 'loading'
      })
    );
    const url = urls.get_session_update_slots;
    const response = yield call(post_api, url, action.payload, true);
    const updateSlots = [];
    response.data.slots.map((slot) => {
      const s = {};
      s.label = slot.teacher_time;
      s.value = slot.epoch_time;
      updateSlots.push(s);
    });
    yield put(
      actions.set_dashboard_variable({
        key: 'session_update_slots',
        payload: updateSlots
      })
    );
    yield put(
      actions.set_dashboard_variable({
        key: 'get_session_update_slots_status',
        payload: 'success'
      })
    );
  } catch (error) {
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      }
    } catch (e) {}
    yield put(
      actions.set_dashboard_variable({
        key: 'get_session_update_slots_status',
        payload: 'failure'
      })
    );
  }
}

export function* scheduleSessionWithStudent(action) {
  try {
    yield put(
      actions.set_dashboard_variable({
        key: 'schedule_session_with_student_status',
        payload: 'loading'
      })
    );
    const url = urls.schedule_session_with_student;
    const response = yield call(post_api, url, action.payload, true);
    // yield put(actions.load_dashboard_sessions());
    const session_uuid = response?.data?.scheduled_sessions[0]?.session_uuid;
    yield put(
      actions.set_dashboard_variable({
        key: 'schedule_session_with_student_response',
        payload: response.data
      })
    );
    yield put(
      actions.set_dashboard_variable({
        key: 'schedule_session_with_student_status',
        payload: 'success'
      })
    );
  } catch (error) {
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      }
    } catch (e) {}
    yield put(
      actions.set_dashboard_variable({
        key: 'schedule_session_with_student_status',
        payload: 'failure',
        error
      })
    );

    yield put(
      actions.set_dashboard_variable({
        key: 'schedule_session_with_student_err_msg',
        payload: error.response.data.message,
        error
      })
    );
  }
}

export function* changeSessionTime(action) {
  try {
    yield put(
      actions.set_dashboard_variable({
        key: 'change_session_time_status',
        payload: 'loading'
      })
    );
    const url = urls.change_session_time;
    const response = yield call(post_api, url, action.payload, true);
    yield put(actions.load_short_dashboard_sessions());

    yield put(
      actions.set_dashboard_variable({
        key: 'change_session_time_status',
        payload: 'success'
      })
    );
  } catch (error) {
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      }
    } catch (e) {}
    yield put(
      actions.set_dashboard_variable({
        key: 'change_session_time_status',
        payload: 'failure',
        error
      })
    );

    yield put(
      actions.set_dashboard_variable({
        key: 'change_session_time_err_msg',
        payload: error.response.data.message,
        error
      })
    );
  }
}

export function* getTwilioToken(action) {
  const url = urls.get_twilio_voice_token;
  const response = yield call(get_api, url, true);
  yield put(actions.set_home_variable('twilio_token', response.data.token));
}

export function* getCallTimer(action) {
  let url = urls.get_active_teacher_consult_call_status;
  url = url.replace(':consult_id', action.payload.consult_id);
  try {
    yield put(actions.set_home_variable('get_consult_session_call_details_status', 'loading'));
    const response = yield call(get_api, url, true);
    yield put(actions.set_home_variable('get_consult_session_call_details', response.data));
    yield put(actions.set_home_variable('get_consult_session_call_details_status', 'success'));
  } catch (error) {
    const err = error;
    yield put(
      actions.set_home_variable('get_consult_session_call_details_error', error.response.data.error)
    );
    yield put(actions.set_home_variable('get_consult_session_call_details_status', 'fail'));
    const err_payload = {
      error,
      type: action.type
    };
  }
}

export default function* dashboardSaga() {
  yield all([takeEvery(t.load_dashboard_sessions, loadDashboardSessions)]);
  yield all([takeEvery(t.load_short_dashboard_sessions, loadShortDashboardSessions)]);
  yield all([takeEvery(t.load_student_details, loadStudentDetails)]);
  yield all([takeEvery(t.WITHDRAW_REPLACEMENT_REQEUEST, withdrawReplacementRequest)]);
  yield all([takeEvery(t.load_student_details_slug, loadStudentDetailsSlug)]);
  yield all([takeEvery(t.schedule_session_with_student, scheduleSessionWithStudent)]);
  yield all([takeEvery(t.cancel_session, cancelSession)]);
  yield all([takeEvery(t.request_replacement, requestReplacement)]);
  yield all([takeEvery(t.get_session_update_slots, getSessionUpdateSlots)]);
  yield all([takeEvery(t.change_session_time, changeSessionTime)]);
  yield all([takeEvery(t.get_twilio_voice_token, getTwilioToken)]);
  yield all([takeEvery(t.get_active_call_status, getCallTimer)]);
}
