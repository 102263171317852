import React from 'react';

import { connect } from 'react-redux';
import screenfull from 'screenfull';
import ReactPlayer from 'react-player';
import * as actions from '../../Redux/action_creators';
import './ClassDetails.css';
import PlayerControler from '../../components/PlayerControler';
import OutsideAlerter from '../../components/FixedComponent/ClickDetecte/index';
import moment from 'moment';
import { deviceInfo } from '../../utils';
import ShowUniqueId from '../../components/popups/ShowUniqueId';
import UpdateStudentCount from '../../components/popups/UpdateStudentCount';
import StudentCountSuccess from '../../components/popups/StudentCountSuccess';

import EditClassDuration from '../../components/popups/EditClassDuration';
import Confirmation from '../../components/popups/Confirmation';
import DashboardScheduleClassPopup from '../../components/popups/DashboardScheduleClassPopup';
import SubscritionPopup from '../../components/popups/CreditPopup';
import Header from '../../../components/util_components/header';
import Footer from '../../../components/new_teacher_wesite/components/footer';

import UploadProgress from '../../components/UploadProgress/UploadProgress';
import ImageCarousel from '../../components/popups/ImageCarousel';
import ThemeCarousel from '../../components/popups/ThemeCarousel';
import ImageSelectionToShare from '../../components/popups/ImageSelectionToShare';
import ScheduleConfirmation from '../../components/popups/ScheduleConfirmation';

import urls from '../../../urls';
import { getCookie, sendEventToClarity } from '../../../util_functions';
import Button from '../../BackPainTherapy/CommonComponents/Button';
import EditSessionNotesPopUp from '../../BackPainTherapy/EditSessionNotesPopUp';
import { withRouter } from '../../../utils/router';
import ChatService, { QueuedData, QueuedType } from 'myt-chat/src/external/ChatService';
import TextWithLinks from '../../../components/util_components/CustomTextWithLink';

interface IState {
  playing: boolean;
  played: number;
  seeking: boolean;
  isMenu: boolean;
  isMobileMenu: boolean;
  isScheduleClass: boolean;
  isProceedSchedule: boolean;
  sessionDetails: any;
  studentProfile: any;
  volume: number;
  startDate: Date;
  startTime: Date;
  checkedRepeatSession: boolean;
  duration: number;
  classDuration: number;
  reason: string;
  session_uuid: string;
  isShowUniquePopup: boolean;
  isShowUpdateStudentCount: boolean;
  isStudentCountUpdated: boolean;
  isStudentProfile: boolean;
  isClassDuration: boolean;
  isClassConfirmation: boolean;
  studentCount: number;
  is_changed_by_teacher: number;
  subscritiopnPopupDataDT: Object;
  subscritiopnPopup: Boolean;
  editPopupData: any;
  files: any;
  isImageCarouselPopup: boolean;
  selectedCarouselImage: number;
  sessionScheduledSuccess: boolean;
  isShowImageSelectionPopup: boolean;
  isShowThemePopup: boolean;
  playerLight: string;
  selectedPhotos: any;
  editSessionNotesPopup: boolean;
  create_therapy_plan_popup: boolean;
  follow_up_popup: boolean;
}
interface IMapStateToProps {
  schedule_class_status: string;
  upload_file_status: string;
  get_file_status: string;
  class_details_status: string;
  student_count_status: string;
  student_profile_status: string;
  class_duration_status: string;
  schedule_class_response: any;
  class_details_response: any;
  get_file_response: any;
  student_count_response: any;
  student_profile_response: any;
  class_duration_response: any;
  scheduleSessionDataInfo: Object;
  delete_file_status: string;
  delete_file_response: any;
  location: any;
  history: any;
  teacher_details: any;
  selected_files: any;
}

interface IMapDispatchToProps {
  scheduleClass: (payload?: any) => void;
  classDetails: (payload?: any) => void;
  updateStudentCount: (payload?: any) => void;
  updateClassDuration: (payload?: any) => void;
  getStudentProfile: (payload?: any) => void;
  getUploadedImage: (payload?: any) => void;
  set_class_details_variable: (key?: any, payload?: any) => void;
  set_upload_file_variable: (key?: any, payload?: any) => void;
  set_home_variable: (key?: any, payload?: any) => void;
  setUploadFile: (files?: any) => void;
}

type ClassDetailsProps = IMapStateToProps & IMapDispatchToProps;

export class ClassDetails extends React.Component<ClassDetailsProps, IState> {
  player: any;
  playerContainerRef: any;

  constructor(props: any) {
    super(props);
    this.state = {
      playing: false,
      played: 0,
      seeking: false,
      volume: 0.6,
      isMenu: false,
      isMobileMenu: false,
      isScheduleClass: false,
      isProceedSchedule: false,
      sessionDetails: null,
      studentProfile: null,
      startDate: new Date(),
      startTime: new Date(),
      checkedRepeatSession: false,
      duration: 30,
      reason: '',
      session_uuid: props.router.params.session_uuid,
      isShowUniquePopup: false,
      isShowUpdateStudentCount: false,
      isStudentCountUpdated: false,
      studentCount: 0,
      isStudentProfile: false,
      isClassDuration: false,
      classDuration: 30,
      is_changed_by_teacher: 0,
      isClassConfirmation: false,
      subscritiopnPopupDataDT: {},
      subscritiopnPopup: false,
      editPopupData: {},
      files: [],
      isImageCarouselPopup: false,
      selectedCarouselImage: 0,
      sessionScheduledSuccess: false,
      editSessionNotesPopup: false,
      isShowImageSelectionPopup: false,
      isShowThemePopup: false,
      playerLight: '',
      selectedPhotos: []
    };
    this.player = React.createRef();
    this.playerContainerRef = React.createRef();
  }

  componentDidMount(): void {
    if (import.meta.env.VITE_APP_STAGE === 'production') {
      document.addEventListener('contextmenu', this.handleRightClick);
      sendEventToClarity();
    }

    let payload = {
      session_uuid: this.state.session_uuid
    };
    this.props.classDetails(payload);
    this.props.getUploadedImage(payload);
  }

  componentWillUnmount(): void {
    localStorage.removeItem('session-details');
    if (import.meta.env.VITE_APP_STAGE === 'production') {
      document.removeEventListener('contextmenu', this.handleRightClick);
    }
  }

  componentDidUpdate(prevProps): void {
    if (
      prevProps.schedule_class_status === 'loading' &&
      this.props.schedule_class_status === 'success'
    ) {
      this.setState({ isScheduleClass: false });
      console.log('this.props.schedule_class_response;', this.props.schedule_class_response);
    }
    if (
      prevProps.schedule_class_status === 'loading' &&
      this.props.schedule_class_status === 'fail'
    ) {
      this.setState({ reason: this.props.schedule_class_response.reason });
    }
    if (
      prevProps.class_details_status === 'loading' &&
      this.props.class_details_status === 'success'
    ) {
      this.props.class_details_response.data.is_recurring =
        this.props.class_details_response.data.is_repeat;
      this.props.class_details_response.data.epoch_start_time = moment(
        `${moment(this.props.class_details_response.data.start_date).format('DD/MM/YYYY')} ${moment(this.props.class_details_response.data.start_time).format('HH:mm A')}`,
        ['DD/MM/YYYY HH:mm A']
      ).valueOf();
      this.setState(
        {
          sessionDetails: this.props.class_details_response.data,
          studentCount: this.props.class_details_response.data.student_count
        },
        () => {
          let payload = {
            student_slug: this.state.sessionDetails?.student_slug
          };
          this.setState({ classDuration: this.state.sessionDetails?.duration });
          this.setState({ playerLight: this.state.sessionDetails?.video_thumbnail });
          this.props.getStudentProfile(payload);
        }
      );
    }
    if (
      prevProps.student_count_status === 'loading' &&
      this.props.student_count_status === 'success'
    ) {
      this.setState({ isStudentCountUpdated: true, isShowUpdateStudentCount: false });
    }
    if (
      prevProps.student_count_status === 'loading' &&
      this.props.student_count_status === 'fail'
    ) {
      this.setState({ isStudentCountUpdated: false, isShowUpdateStudentCount: true });
    }
    if (
      prevProps.student_profile_status === 'loading' &&
      this.props.student_profile_status === 'success'
    ) {
      this.setState({ studentProfile: this.props.student_profile_response.details }, () => {});
    }
    if (
      prevProps.class_duration_status === 'loading' &&
      this.props.class_duration_status === 'success'
    ) {
      this.setState({ isClassDuration: false, isClassConfirmation: true });
    }
    if (prevProps.upload_file_status === 'loading' && this.props.upload_file_status === 'success') {
      this.setState({ files: [] }, () => {
        this.props.getUploadedImage({ session_uuid: this.state.session_uuid });
      });
    }
    if (prevProps.get_file_status === 'loading' && this.props.get_file_status === 'success') {
      this.props.get_file_response.data &&
        this.props.get_file_response.data.items.length &&
        this.props.get_file_response.data.items.map((_) => {
          _.isChecked = false;
        });
      this.setState({ files: this.props.get_file_response.data.items });
    }
  }

  sharePhotos = async (selectedPhotos) => {
    this.props.set_upload_file_variable('selected_files', selectedPhotos);
    if (selectedPhotos.length) {
      this.setState({
        isShowImageSelectionPopup: false,
        isShowThemePopup: false,
        isImageCarouselPopup: false
      });
      selectedPhotos.forEach((data: { uuid: string; signed_url: string; filename: string }) => {
        ChatService.setQueuedTask(QueuedType.sharePhotos, {
          ...data,
          student_uuid: this.state.studentProfile.uuid
        });
      });

      ChatService.openP2PChat(this.state.studentProfile.uuid);
    }
    let newFiles = this.state.files;
    newFiles.map((_) => {
      _.isChecked = false;
    });
  };

  handlePause = () => {
    this.setState({ playing: !this.state.playing, playerLight: '' });
  };

  handleProgress = (changeState) => {
    if (!this.state.seeking) {
      if (changeState.played === 1) {
        this.setState({ played: 0, playing: false });
      } else {
        this.setState({ played: changeState.played });
      }
    }
  };

  handleRightClick = (e) => {
    if (e.button === 2) {
      e.preventDefault();
    }
  };

  changeSliderValue = (event) => {
    if (event === 100) {
      event -= 0.1;
    }
    this.setState({ played: Number(event) / 100 });
    this.player.seekTo(Number(event) / 100);
  };

  handleSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };

  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
  };

  handleClickFullscreen = () => {
    screenfull.toggle(this.playerContainerRef.current);
  };

  handleClosePopup = () => {
    this.setState({ isScheduleClass: false });
  };

  openProceedSchedulePopup = () => {
    this.setState({ isProceedSchedule: true, isScheduleClass: false });
  };

  openScheduleClassPopup = () => {
    this.setState({ isScheduleClass: true });
  };

  openUniquePopup = () => {
    this.setState({ isShowUniquePopup: true });
  };

  openClassDurationPopup = () => {
    this.setState({ isClassDuration: true });
  };

  changeDate = (startDate) => {
    this.setState({ startDate });
  };

  changeTime = (startTime) => {
    this.setState({ startTime });
  };

  changeDuration = (duration) => {
    this.setState({ duration });
  };

  changeClassDuration = (classDuration) => {
    this.setState({ classDuration });
  };

  changeTeacherSelection = (is_changed_by_teacher) => {
    this.setState({ is_changed_by_teacher: is_changed_by_teacher === 0 ? 1 : 0 });
  };

  changeMakeRepeat = (checkedRepeatSession) => {
    this.setState({ checkedRepeatSession: !checkedRepeatSession });
  };

  scheduleClass = () => {
    let start_time = moment(
      `${moment(this.state.startDate).format('DD/MM/YYYY')} ${moment(this.state.startTime).format('HH:mm A')}`,
      ['DD/MM/YYYY HH:mm A']
    ).valueOf();
    let deviceInf = deviceInfo();
    let sessionDetails = this.state.sessionDetails;
    let payload = {
      student_uuid: sessionDetails.student_uuid,
      start_time,
      duration: this.state.duration,
      is_recurring: this.state.checkedRepeatSession ? 1 : 0,
      from_web: deviceInf.app_platform === 'web_app' && deviceInf.device_type === 'desktop' ? 1 : 0,
      scheduled_by_cms: 0,
      yoga_with_friends_request: 0,
      is_price_split: 0,
      use_credit: 0
    };
    this.props.scheduleClass(payload);
  };

  updateClassDuration = () => {
    let payload = {
      session_uuid: this.state.session_uuid,
      duration: this.state.classDuration
    };
    this.props.updateClassDuration(payload);
  };

  closeProceedSchedulePopup = () => {
    this.setState({ isProceedSchedule: false });
  };

  closeUniquePopup = () => {
    this.setState({ isShowUniquePopup: false });
  };

  closeClassDurationPopup = () => {
    this.setState({ isClassDuration: false });
  };

  closeClassDurationConfirmationPopup = () => {
    this.setState({ isClassConfirmation: false });
  };

  closeUpdateStudentCountPopup = () => {
    this.props.set_class_details_variable('student_count_response', null);
    this.setState({ isShowUpdateStudentCount: false });
  };

  closeStudentCountSuccessPopup = () => {
    this.setState({ isStudentCountUpdated: false });
  };

  closeStudentProfilePopup = () => {
    this.setState({ isStudentProfile: false });
  };

  incrementDecrement = (value) => {
    let newCount = this.state.studentCount + value;
    if (newCount >= 1) {
      this.setState({ studentCount: newCount });
    }
  };

  openCreditPopup = (dt) => {
    this.setState({
      subscritiopnPopupDataDT: dt,
      isScheduleClass: false,
      subscritiopnPopup: true
    });
  };

  closesubscriptionPopupFun = () => {
    this.setState({
      isScheduleClass: false,
      sessionScheduledSuccess: true
    });
  };

  closeSessionSuccess = () => {
    this.setState({
      sessionScheduledSuccess: false
    });
  };

  closeSchedulePopup = (prevProps) => {
    this.setState({
      isScheduleClass: false
    });
  };

  closeScheduleClassPopup = () => {
    this.setState({
      isScheduleClass: false
    });
  };

  closesubscriptionPopupFail = () => {
    this.setState({
      editPopupData: this.props.scheduleSessionDataInfo['subscriptionPayload'],
      subscritiopnPopup: false,
      isScheduleClass: true
    });
  };

  closeSubscriptionPopupdt = () => {
    this.setState({
      subscritiopnPopup: false
    });
    this.props.set_home_variable('scheduleSessionDataInfoErrMsg', '');
    this.props.set_home_variable('scheduleSessionDataInfo', {});
  };

  closesubscriptionPopup = () => {
    this.setState({
      subscritiopnPopup: false,
      sessionScheduledSuccess: true
    });
  };

  onVolumeChange = (e) => {
    this.setState({ volume: Number(e) });
  };

  handleAttachFile = (e) => {
    let payload = {
      files: e.target.files,
      session_uuid: this.state.session_uuid
    };
    this.props.setUploadFile(payload);
  };

  closeMenuCard = () => {
    this.setState({ isMenu: false });
  };
  closeMobileMenuCard = () => {
    this.setState({ isMobileMenu: false });
  };

  ref = (player) => {
    this.player = player;
  };
  containerRef = (playerContainerRef) => {
    this.playerContainerRef = playerContainerRef;
  };

  openImageCarousel = (imageIndex) => {
    this.setState({ isImageCarouselPopup: true, selectedCarouselImage: imageIndex });
  };

  closePopupImageCarousel = () => {
    this.setState({ isImageCarouselPopup: false });
  };

  openProfile = (e) => {
    e.stopPropagation();
    if (this.state.studentProfile) {
      this.props.router.navigate(`/yoga-journey/${this.state.studentProfile.slug}`);
    } else {
      console.error('studentProfile is null');
    }
  };

  showImageSelectionPopup = () => {
    this.setState({ isShowImageSelectionPopup: true });
  };

  closeImageSelectionPopup = () => {
    let newFiles = this.state.files;
    newFiles.map((file) => {
      file.isChecked = false;
    });
    this.setState({ isShowImageSelectionPopup: false, files: newFiles });
  };

  customizePhotos = (selectedPhotos) => {
    let newFiles = this.state.files;
    newFiles.map((file) => {
      file.isChecked = false;
    });
    this.setState({ isShowImageSelectionPopup: false, isShowThemePopup: true, selectedPhotos });
  };

  closeThemePopup = () => {
    this.setState({ isShowThemePopup: false });
  };

  updateStudentCount = () => {
    if (this.state.studentProfile && this.state.studentProfile.membership_plan) {
      let payload = {
        session_uuid: this.state.session_uuid,
        student_count: this.state.studentCount
      };
      this.props.updateStudentCount(payload);
    } else {
      this.props.set_class_details_variable('student_count_response', {
        message: 'Unable to update student count for trial user.'
      });
    }
  };

  render() {
    const { sessionDetails, volume } = this.state;
    const playerEvents = {
      onPlayPause: this.handlePause,
      changeSliderValue: this.changeSliderValue,
      handleSeekMouseUp: this.handleSeekMouseUp,
      handleSeekMouseDown: this.handleSeekMouseDown,
      handleClickFullscreen: this.handleClickFullscreen,
      played: this.state.played,
      playing: this.state.playing,
      volume: volume,
      onVolumeChange: this.onVolumeChange
    };

    const classDetailsParams = {
      handleClosePopup: this.handleClosePopup,
      openProceedSchedulePopup: this.openProceedSchedulePopup,
      changeDate: this.changeDate,
      changeTime: this.changeTime,
      changeMakeRepeat: this.changeMakeRepeat,
      changeDuration: this.changeDuration,
      scheduleClass: this.scheduleClass,
      duration: this.state.duration,
      startDate: this.state.startDate,
      startTime: this.state.startTime,
      checkedRepeatSession: this.state.checkedRepeatSession,
      reason: this.state.reason
    };

    const proceedScheduleClass = {
      closeProceedSchedulePopup: this.closeProceedSchedulePopup
    };

    const uniquePopup = {
      closeUniquePopup: this.closeUniquePopup,
      student_slug: sessionDetails?.student_slug,
      student_thumb: sessionDetails?.student_thumb
    };

    const updateStudent = {
      closeUpdateStudentCountPopup: this.closeUpdateStudentCountPopup,
      incrementDecrement: this.incrementDecrement,
      updateStudentCount: this.updateStudentCount,
      studentCount: this.state.studentCount,
      studentCountErr: this.props.student_count_response
    };

    const countSuccess = {
      closeStudentCountSuccessPopup: this.closeStudentCountSuccessPopup
    };

    const profileParams = {
      closeStudentProfilePopup: this.closeStudentProfilePopup,
      openScheduleClassPopup: this.openScheduleClassPopup,
      openUniquePopup: this.openUniquePopup,
      studentProfile: this.state.studentProfile,
      sessionDetails: this.state.sessionDetails
    };

    const classDurationParams = {
      closeClassDurationPopup: this.closeClassDurationPopup,
      changeClassDuration: this.changeClassDuration,
      changeTeacherSelection: this.changeTeacherSelection,
      updateClassDuration: this.updateClassDuration,
      classDuration: this.state.classDuration,
      is_changed_by_teacher: this.state.is_changed_by_teacher,
      sessionDetails: this.state.sessionDetails
    };
    const classDurationConfirmationParams = {
      closeClassDurationConfirmationPopup: this.closeClassDurationConfirmationPopup
    };
    if (this.state.editSessionNotesPopup) {
      return (
        <EditSessionNotesPopUp
          data={sessionDetails.session_notes || ''}
          sessionUUID={this.state?.session_uuid || ''}
          onClose={() => this.setState({ editSessionNotesPopup: false })}
          onSubmit={() => {
            let payload = {
              session_uuid: this.state.session_uuid
            };
            this.props.classDetails(payload);
            this.setState({ editSessionNotesPopup: false });
          }}
        />
      );
    }
    const showFirstBorder =
      sessionDetails?.IsBackPainSubscribed || sessionDetails?.session_notes?.length > 0 || false;
    const showSecondBorder = sessionDetails?.session_notes?.length > 0 || false;

    return (
      <>
        <Header />
        <div className="bg-mytgrey-litev1 md:py-12 lg:px-0 zm:py-5 zm:px-6 flex justify-center pb-24">
          <div className="lg:w-3/4 zm:w-full 2xl:w-3/5">
            <div className="flex items-center">
              <a
                href="/mysessions"
                className="text-black text-opacity-40 font-semibold text-sm pr-2 hover:underline hover:text-primary"
              >
                My Classes
              </a>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.75 13.5L11.25 9L6.75 4.5"
                  stroke="black"
                  strokeOpacity="0.4"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-black text-opacity-40 font-semibold text-sm pl-2">
                {sessionDetails?.student_name}
              </p>
            </div>
            <div className="flex justify-between pt-5">
              <div>
                <h3 className="md:text-38px zm:text-xl font-black text-black">{`${sessionDetails?.student_name || ''}${sessionDetails?.class_type === 'BACK_PAIN_CONSULT' ? '(Back Pain Consult)' : sessionDetails?.class_type === 'BACK_PAIN_THERAPY' ? '(Back Pain 1-on-1)' : ''}`}</h3>
              </div>
              <div className="relative lg:flex zm:hidden">
                <button
                  className="btn-white rounded-lg px-4 py-3 text-15 font-semibold text-mytpurple"
                  onClick={($event) => {
                    let studentUUID = this.state.sessionDetails.student_uuid;
                    let time = this.state.sessionDetails.start_time;

                    sessionDetails?.class_type === 'BACK_PAIN_CONSULT' ||
                    sessionDetails?.class_type === 'BACK_PAIN_THERAPY'
                      ? this.props.router.navigate(`/backpain-client-profile?id=${studentUUID}`, {
                          state: { student_time: time || '' }
                        })
                      : this.openProfile($event);
                  }}
                >
                  View Profile
                </button>
                {/* {this.state.isStudentProfile && <StudentProfile {...profileParams} />} */}
                {(sessionDetails?.class_type === 'YOGA' ||
                  sessionDetails?.class_type === 'BACK_PAIN_CONSULT' ||
                  sessionDetails?.class_type === 'BACK_PAIN_THERAPY') && (
                  <button
                    className="flex items-center btn-white rounded-lg px-4 py-3 text-15 font-semibold text-mytpurple ml-2"
                    onClick={() => this.setState({ isMenu: !this.state.isMenu })}
                  >
                    More {''}
                    <svg
                      className="ml-4"
                      width="15"
                      height="10"
                      viewBox="0 0 15 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.92822 2.42822L7.49965 7.99965L13.0711 2.42822"
                        stroke="#264150"
                        strokeWidth="1.85714"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
                <>
                  <OutsideAlerter close_card={this.closeMenuCard}>
                    {this.state.isMenu && (
                      <div className="absolute z-10 top-12 xl:-right-32 lg:-right-0 bg-white flex flex-col justify-center border border-gray-200 rounded-lg cursor-pointer py-4 xl:px-6 lg:px-4">
                        {/* <div className='class-btn-size text-15.5 text-black font-inter font-semibold py-2' onClick={this.openClassDurationPopup}>
                          Edit class duration
                        </div> */}
                        <div
                          className="class-btn-size text-15.5 text-black font-inter font-semibold py-2"
                          onClick={() =>
                            this.setState({
                              isShowUpdateStudentCount: !this.state.isShowUpdateStudentCount
                            })
                          }
                        >
                          Update student count
                        </div>
                        <div
                          className="class-btn-size text-15.5 text-black font-inter font-semibold py-2"
                          onClick={this.openUniquePopup}
                        >
                          Show Student Unique ID
                        </div>
                      </div>
                    )}
                  </OutsideAlerter>
                  {this.state.isClassConfirmation && (
                    <Confirmation {...classDurationConfirmationParams} />
                  )}
                  {this.state.isClassDuration && <EditClassDuration {...classDurationParams} />}
                  {this.state.isShowUpdateStudentCount && <UpdateStudentCount {...updateStudent} />}
                  {this.state.isStudentCountUpdated && <StudentCountSuccess {...countSuccess} />}
                  {this.state.isShowUniquePopup && <ShowUniqueId {...uniquePopup} />}
                </>
              </div>
            </div>
            <div className="flex flex-wrap pt-5">
              <div className="flex items-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.6667 2.66699H3.33333C2.59695 2.66699 2 3.26395 2 4.00033V13.3337C2 14.07 2.59695 14.667 3.33333 14.667H12.6667C13.403 14.667 14 14.07 14 13.3337V4.00033C14 3.26395 13.403 2.66699 12.6667 2.66699Z"
                    stroke="#999999"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.6665 1.33301V3.99967"
                    stroke="#999999"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.3335 1.33301V3.99967"
                    stroke="#999999"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2 6.66699H14"
                    stroke="#999999"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-mytgrey-contactHeading text-15 font-medium pl-2">
                  {sessionDetails?.start_date}
                </p>
              </div>
              <div className="flex items-center ml-5">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.0026 14.6663C11.6845 14.6663 14.6693 11.6816 14.6693 7.99967C14.6693 4.31778 11.6845 1.33301 8.0026 1.33301C4.32071 1.33301 1.33594 4.31778 1.33594 7.99967C1.33594 11.6816 4.32071 14.6663 8.0026 14.6663Z"
                    stroke="#999999"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 4V8L10.6667 9.33333"
                    stroke="#999999"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-mytgrey-contactHeading text-15 font-medium pl-2">
                  {sessionDetails?.start_time}
                </p>
              </div>
              <div className="flex lg:items-center md:ml-5 zm:ml-0 lg:mt-0 zm:mt-3">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.6001 5.59961H14.4001"
                    stroke="#999999"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.6001 10.4004H14.4001"
                    stroke="#999999"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.3998 0.799805L4.7998 15.1998"
                    stroke="#999999"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.2001 0.799805L9.6001 15.1998"
                    stroke="#999999"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {sessionDetails?.class_type === 'YOGA' ? (
                  <p className="text-mytgrey-contactHeading text-15 font-medium pl-2 leading-normal">
                    {sessionDetails?.one_on_one_class_count}th 1-on-1 class with you
                  </p>
                ) : sessionDetails?.class_type !== 'BACK_PAIN_CONSULT' &&
                  sessionDetails?.class_type !== 'BACK_PAIN_THERAPY' ? (
                  <p className="text-mytgrey-contactHeading text-15 font-medium pl-2 leading-normal">
                    Students joined:{' '}
                    {sessionDetails?.group_class_participant_details?.joined_participant} (Members:{' '}
                    {sessionDetails?.group_class_participant_details?.member_participant}, Trial:{' '}
                    {sessionDetails?.group_class_participant_details?.non_member_participant})
                    Cancelled:{' '}
                    {sessionDetails?.group_class_participant_details?.cancelled_participant}, No
                    shows: {sessionDetails?.group_class_participant_details?.no_show_participant}
                  </p>
                ) : sessionDetails?.class_type !== 'BACK_PAIN_THERAPY' ? (
                  <p className="text-mytgrey-contactHeading text-15 font-medium pl-2 leading-normal">
                    {sessionDetails?.one_on_one_class_count || ''}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="relative lg:hidden zm:flex pt-3">
              <button
                className="btn-white rounded-lg px-4 text-15 font-semibold text-mytpurple py-3"
                onClick={($event) => this.openProfile($event)}
              >
                View Profile
              </button>
              {sessionDetails?.class_type === 'YOGA' && (
                <button
                  className="flex items-center btn-white rounded-lg px-4 text-15 font-semibold text-mytpurple ml-2"
                  onClick={() => this.setState({ isMobileMenu: !this.state.isMobileMenu })}
                >
                  More {''}
                  <svg
                    className="ml-4"
                    width="15"
                    height="10"
                    viewBox="0 0 15 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.92822 2.42822L7.49965 7.99965L13.0711 2.42822"
                      stroke="#264150"
                      strokeWidth="1.85714"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              )}
              <OutsideAlerter close_card={this.closeMobileMenuCard}>
                {this.state.isMobileMenu && (
                  <div className="absolute z-10 top-14 left-10 xl:-right-34 lg:-right-0 bg-white flex flex-col justify-center border border-gray-200 rounded-lg cursor-pointer py-4 px-6">
                    {sessionDetails?.class_type !== 'BACK_PAIN_CONSULT' &&
                    sessionDetails?.class_type !== 'BACK_PAIN_THERAPY' ? (
                      <div
                        className="class-btn-size text-15.5 text-black font-inter font-semibold py-2"
                        onClick={() =>
                          this.setState({
                            isShowUpdateStudentCount: !this.state.isShowUpdateStudentCount
                          })
                        }
                      >
                        Update student count
                      </div>
                    ) : null}
                    <div
                      className="class-btn-size text-15.5 text-black font-inter font-semibold py-2"
                      onClick={this.openUniquePopup}
                    >
                      Show Student Unique ID
                    </div>
                  </div>
                )}
              </OutsideAlerter>
            </div>
            <div className="w-full mt-8 mb-8 rounded-lg flex flex-col items-center justify-center">
              <div className="w-full ">
                {sessionDetails?.video_url === '' ? (
                  <div className="h-midScreen flex items-center justify-center relative overflow-hidden shadow rounded-lg border-solid border-2 border-teal-500 bg-white">
                    <p className="font-bold text-mytTeacherTextGray lg:text-22 zm:text-base leading-snug">
                      Recordings is not yet available
                    </p>
                  </div>
                ) : (
                  <div className="relative overflow-hidden shadow rounded-lg border-solid border-2 border-teal-500">
                    <div className="flex items-center justify-center">
                      <div ref={this.playerContainerRef} className="player-wrapper w-full">
                        <ReactPlayer
                          ref={this.ref}
                          width={'100%'}
                          height={'100%'}
                          playing={this.state.playing}
                          url={sessionDetails?.video_url}
                          onProgress={this.handleProgress}
                          volume={volume}
                          light={this.state.playerLight}
                        />
                        <PlayerControler {...playerEvents} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full">
                {sessionDetails?.class_type === 'BACK_PAIN_CONSULT' ||
                sessionDetails?.class_type === 'BACK_PAIN_THERAPY' ? (
                  <>
                    {sessionDetails?.ShowFollowUpConsultButton ? (
                      <div
                        className="flex flex-row justify-between py-60px"
                        style={{ borderBottom: showFirstBorder ? '1px solid #5B697F' : '' }}
                      >
                        <div className="flex flex-col">
                          <span className="text-mytTeacherTextGray font-inter text-22px font-extrabold">
                            Want to schedule another consult with{' '}
                            {sessionDetails?.student_name || ''}?{' '}
                          </span>
                          <span className="text-black font-inter text-16px font-normal leading-24px mt-10px">
                            This option should be mindfully used in agreement with the student.
                          </span>
                        </div>
                        <Button
                          btntext="Book Follow up Consult"
                          color="btn-mytPurple"
                          btnclassNames="mr-2.5"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.props.router.navigate(`/book-consult`, {
                              state: {
                                student_uuid: this.state.sessionDetails.student_uuid,
                                student_name: this.state.studentProfile.full_name
                              }
                            });
                          }}
                        />
                      </div>
                    ) : null}
                    {sessionDetails?.IsBackPainSubscribed ? (
                      <div
                        className="flex flex-row justify-between py-60px"
                        style={{ borderBottom: showSecondBorder ? '1px solid #5B697F' : '' }}
                      >
                        <div className="flex flex-col">
                          <span className="text-mytTeacherTextGray font-inter text-22px font-extrabold">
                            Want to schedule another 1-on-1 session with{' '}
                            {sessionDetails?.student_name || ''}?{' '}
                          </span>
                          <span className="text-black font-inter text-16px font-normal leading-24px mt-10px">
                            This option should be mindfully used in agreement with the student.
                          </span>
                        </div>
                        <Button
                          btntext="Book a 1-on-1 session"
                          color="btn-primary"
                          btnclassNames="mr-2.5"
                          onClick={() => {
                            let studentUUID = this.state.sessionDetails.student_uuid;
                            let time = this.state.sessionDetails.start_time;
                            this.props.router.navigate(`/book-coaching?id=${studentUUID}`, {
                              state: { timeZone: time }
                            });
                          }}
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
                {sessionDetails?.session_notes?.length > 0 ? (
                  <div className="flex flex-row justify-between py-60px">
                    <div className="flex flex-col">
                      <span className="text-mytTeacherTextGray font-inter text-22px font-extrabold">
                        Session notes
                      </span>
                      <span className="text-black font-inter text-16px font-normal leading-24px mt-10px w-full max-w-[600px] break-words">
                        <TextWithLinks text={sessionDetails.session_notes || ''} />
                      </span>
                    </div>
                    <Button
                      btntext="Edit session notes"
                      color="btn-white-default"
                      btnclassNames="mt-15px ml-60px"
                      onClick={() => {
                        this.setState({ editSessionNotesPopup: true });
                      }}
                      classNames="text-18.5px"
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="md:inline zm:hidden">
              {this.state.files.length > 0 &&
                this.props.class_details_response?.data?.class_type === 'YOGA' && (
                  <div className="md:p-40px md:pr-38px zm:px-25px zm:py-40px mb-40px w-full bg-white rounded-lg border shadow-md flex flex-col justify-center">
                    <div className="mb-15px text-22px font-bold">
                      Photos captured during the class ({this.state.files.length})
                    </div>
                    <div
                      className={`flex gap-10px flex-wrap max-h-400px ${this.state.isImageCarouselPopup ? '' : 'overflow-y-scroll'}`}
                    >
                      <div
                        className="bg-mytGrayLightV3 rounded-lg border"
                        style={{ width: 'auto' }}
                      >
                        <div
                          className="flex justify-center items-center h-full p-5px"
                          style={{ width: 'auto', height: '100px' }}
                        >
                          <label
                            htmlFor="dropzone-file"
                            className="flex flex-col justify-center items-center w-full h-full bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                          >
                            <div className="flex flex-col justify-center items-center pt-5 pb-4 px-29px">
                              <svg
                                className="mb-3 w-6 h-6 text-gray-400"
                                viewBox="0 0 16 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.80549 14.5085V0.446022H9.19185V14.5085H6.80549ZM0.967419 8.67045V6.28409H15.0299V8.67045H0.967419Z"
                                  fill="#757575"
                                />
                              </svg>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">Upload photos</span>
                              </p>
                            </div>
                            <input
                              id="dropzone-file"
                              multiple
                              type="file"
                              accept=".jpg, .jpeg, .png"
                              onChange={this.handleAttachFile}
                              className="hidden"
                            />
                          </label>
                        </div>
                      </div>
                      <UploadProgress />
                      {this.state.files.map((img: any, i: number) => {
                        return (
                          <div
                            key={img?.uuid}
                            className="bg-mytGrayLightV3 rounded-lg border cursor-pointer"
                            style={{ width: 'fit-content', blockSize: 'fit-content' }}
                            onClick={() => this.openImageCarousel(i)}
                          >
                            <div className="flex justify-center items-center rounded-lg w-max h-full">
                              <img
                                src={img?.signed_url}
                                style={{ maxWidth: '170px', height: '100px' }}
                                className="rounded-lg w-full h-full object-contain"
                                alt={img?.filename}
                              />
                            </div>
                          </div>
                        );
                      })}
                      {this.state.isImageCarouselPopup ? (
                        <ImageCarousel
                          files={this.state.files}
                          selectedCarouselImage={this.state.selectedCarouselImage}
                          closePopupImageCarousel={this.closePopupImageCarousel}
                          getSelectedImage={this.sharePhotos}
                        />
                      ) : null}
                    </div>
                    <div className="flow-root mt-30px">
                      <button
                        onClick={this.showImageSelectionPopup}
                        className="btn btn-primary md:text-17 zm:text-17 md:py-2 zm:py-3 font-inter font-semibold md:mb-0 zm:mb-9px h-49px"
                      >
                        Share with student
                      </button>
                    </div>
                  </div>
                )}
            </div>
            {this.state.isShowThemePopup && (
              <ThemeCarousel
                selectedPhotos={this.state.selectedPhotos}
                student_name={sessionDetails?.student_name}
                getSelectedImage={this.sharePhotos}
                closeThemePopup={this.closeThemePopup}
              />
            )}
            {this.state.isShowImageSelectionPopup && (
              <ImageSelectionToShare
                files={this.state.files}
                student_name={sessionDetails?.student_name}
                getSelectedImage={this.sharePhotos}
                closeImageSelectionPopup={this.closeImageSelectionPopup}
                customizePhotos={this.customizePhotos}
              />
            )}
            {sessionDetails?.class_type === 'YOGA' && (
              <div className="flex lg:flex-row zm:flex-col justify-between w-full">
                <div>
                  <p className="font-bold text-mytTeacherTextGray lg:text-22 zm:text-base leading-snug">
                    Want to schedule another 1-on-1 class with {sessionDetails?.student_name}?
                  </p>
                  <p className="lg:text-base zm:text-15px font-normal text-black lg:pt-10px leading-4">
                    This option should be mindfully used in agreement with the student.
                  </p>
                </div>
                <div>
                  <button
                    onClick={this.openScheduleClassPopup}
                    className="btn btn-primary lg:h-60px zm:h-38px px-25px lg:mt-0 zm:mt-19px lg:text-18.5px zm:text-13.5 font-semibold"
                  >
                    Book a 1-on-1 Session
                  </button>
                  {this.state.isScheduleClass && (
                    <DashboardScheduleClassPopup
                      editPopupData={this.state.sessionDetails}
                      closePopup={this.closeScheduleClassPopup}
                      closeSchedulePopup={this.closeSchedulePopup}
                      openCreditPopup={(dt) => this.openCreditPopup(dt)}
                      closesubscriptionPopupFun={this.closesubscriptionPopupFun}
                    />
                  )}
                  {this.state.sessionScheduledSuccess && (
                    <ScheduleConfirmation
                      closeSessionSuccess={this.closeSessionSuccess}
                    ></ScheduleConfirmation>
                  )}
                  {this.state.subscritiopnPopup && (
                    <SubscritionPopup
                      closesubscriptionPopupFail={this.closesubscriptionPopupFail}
                      closesubscriptionPopup={this.closesubscriptionPopup}
                      subscritiopnPopupDataDT={this.state.subscritiopnPopupDataDT}
                      closePopup={this.closeSubscriptionPopupdt}
                      subscriptionPopupData={'a093d28d-76c5-4b6a-9b26-bda1ba53515c'}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    schedule_class_status: state.classdetails.schedule_class_status,
    schedule_class_response: state.classdetails.schedule_class_response,
    class_details_status: state.classdetails.class_details_status,
    class_details_response: state.classdetails.class_details_response,
    student_count_status: state.classdetails.student_count_status,
    student_count_response: state.classdetails.student_count_response,
    student_profile_status: state.classdetails.student_profile_status,
    student_profile_response: state.classdetails.student_profile_response,
    class_duration_status: state.classdetails.class_duration_status,
    class_duration_response: state.classdetails.class_duration_response,
    scheduleSessionDataInfo: state.dashboard.scheduleSessionDataInfo,
    upload_file_status: state.uploadFile.upload_file_status,
    get_file_status: state.uploadFile.get_file_status,
    get_file_response: state.uploadFile.get_file_response,
    delete_file_status: state.uploadFile.delete_file_status,
    delete_file_response: state.uploadFile.delete_file_response,
    teacher_details: state.profile.teacher_details,
    selected_files: state.uploadFile.selected_files
  };
};

const mapDispatchToProps = (dispatch): IMapDispatchToProps => {
  return {
    set_home_variable: (key: any, payload: any) => {
      dispatch(actions.set_home_variable(key, payload));
    },
    classDetails: (payload) => {
      dispatch(actions.classDetails(payload));
    },
    updateStudentCount: (payload) => {
      dispatch(actions.updateStudentCount(payload));
    },
    updateClassDuration: (payload) => {
      dispatch(actions.updateClassDuration(payload));
    },
    getStudentProfile: (payload) => {
      dispatch(actions.getStudentProfile(payload));
    },
    getUploadedImage: (payload) => {
      dispatch(actions.getUploadedImage(payload));
    },
    scheduleClass: (payload) => {
      dispatch(actions.scheduleClass(payload));
    },
    set_class_details_variable: (key: any, payload: any) => {
      dispatch(actions.set_class_details_variable(key, payload));
    },
    set_upload_file_variable: (key: any, payload: any) => {
      dispatch(actions.set_upload_file_variable(key, payload));
    },
    setUploadFile: (files) => {
      dispatch(actions.setUploadFile(files));
    }
  };
};

const ClassDetailsWithRedux = connect(mapStateToProps, mapDispatchToProps)(ClassDetails);
const ClassDetailsWithRouterRedux = withRouter(ClassDetailsWithRedux);

export default ClassDetailsWithRouterRedux;
