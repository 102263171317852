import React, { useState } from 'react';
import { SlotsSelectedIcon } from '../../../svg';
import { getCookie } from '../../util_functions';

function SlotsForTherapy({
  state,
  customClass,
  isSelected,
  onClick,
  teachersList = [],
  displayTime = '',
  toggleSelfAvailaiblity = false,
  slotPicked
}) {
  const [isHovered, setIsHovered] = useState(false);

  let backgroundColor;

  if (state === 'prior_coaches' || state === 'self_availibility') {
    backgroundColor = 'bg-primary';
  } else if (state === 'any_coaches') {
    backgroundColor = 'bg-lightOrangeBGColor';
  } else if (state === 'no_coaches') {
    backgroundColor = 'bg-orangeLiteColor';
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const teacherUUID = getCookie('teacher_uuid');
  const modifiedTeacherList =
    toggleSelfAvailaiblity && teacherUUID
      ? [...teachersList].filter((teacher) => teacher.uuid === teacherUUID)
      : [...teachersList];
  return (
    <div
      className={`${customClass} ${
        isSelected ? 'bg-mytgreen' : backgroundColor
      } flex items-center justify-center relative border`}
      style={{ width: '52px', height: '41px', borderColor: '#FFF' }}
      onMouseEnter={state === 'no_coaches' ? null : handleMouseEnter}
      onMouseLeave={state === 'no_coaches' ? null : handleMouseLeave}
      onClick={state === 'no_coaches' ? null : onClick}
    >
      {isSelected ? <SlotsSelectedIcon /> : null}
      {state === 'no_coaches'
        ? null
        : isHovered && (
            <div className="absolute top-45px left-0 border border-mytBgReplacement shadow-borderShadow bg-white py-25px px-13px w-max rounded-7px z-9">
              <div className="text-mytgrey-title text-left font-medium text-12.5px leading-normal font-Inter mb-10px">
                <span>Date:</span>
                <br className="text-2xl" />
                <span className="text-mytblack font-bold">{slotPicked.date_val || ''}</span>
                <br />
                <br />
                <span>Client Time selected:</span>
                <br />
                <span className="text-mytblack font-bold">{slotPicked.time_selected || ''}</span>
                <br />
                <br />
                <span>Your local time:</span>
                <br />
                <span className="text-mytblack font-bold">{`${displayTime}`}</span>
                <br />
                <br />
              </div>
              <div className=" flex flex-col gap-12px mt-8px">
                {modifiedTeacherList.map((teacher) => {
                  const isPreviousTeacher =
                    teacher?.is_previous_teacher || state === 'self_availibility' || false;

                  return (
                    <div
                      className="text-15.5px leading-19px font-semibold flex gap-5px items-center"
                      key={teacher.uuid}
                    >
                      <div
                        className={`h-12px w-12px ${
                          isPreviousTeacher ? 'bg-primary' : 'bg-lightOrangeBGColor'
                        }`}
                      />
                      <div>{teacher?.name || ''}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
    </div>
  );
}

export default SlotsForTherapy;
