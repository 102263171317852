import React from 'react';

const parseTextToLinks = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part, index) => {
    if (urlRegex.test(part)) {
      return (
        <>
          <br />
          <a
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
            className="underline text-[#4332df]"
          >
            {part}
          </a>
          <br />
        </>
      );
    }
    return part;
  });
};

function TextWithLinks({ text }) {
  return <>{parseTextToLinks(text)}</>;
}

export default TextWithLinks;
