import React, { useEffect, useState } from 'react';
import { format, addMonths, subMonths, subDays, addDays } from 'date-fns';
import './RangePicker.css';
import { LeftIcon, RighIcon } from '../../../../../svg';

function MonthPicker(props) {
  const handlePreviousMonth = () => {
    const selectedDate = subMonths(props.date, 1);
    props?.onMonthChange([selectedDate, addDays(selectedDate, props.enableDays)]);
  };

  const handleNextMonth = () => {
    const selectedDate = addMonths(props.date, 1);
    props?.onMonthChange([selectedDate, addDays(selectedDate, props.enableDays)]);
  };

  return (
    <div className="date-picker zm:w-full sm:w-auto">
      <div className="flex flex-row gap-10px md:ml-95px">
        <div className="cursor-pointer" onClick={handlePreviousMonth}>
          {LeftIcon()}
        </div>
        <span className="month-label ">{format(props.date, 'MMMM, yyyy')}</span>
        <div className="cursor-pointer" onClick={handleNextMonth}>
          {RighIcon()}
        </div>
      </div>
      {/* Additional date picker content */}
    </div>
  );
}

export default MonthPicker;
