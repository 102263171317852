import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { addDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { BredcrumbIcon, CloseIcon } from '../../../svg';

import Checkbox from '../../common/CheckBox/index';

import SlotsForTherapy from './SlotsForTherapy';
import TimeSlots from './TimeSlots';
import urls from '../../urls';
import { post_api } from '../../redux/api_funcs';
import RangePicker from './components/RangePicker/RangePicker';
import { getCookie, sendEventToClarity } from '../../util_functions';
import { OrangeTick, RedCancelIcon } from '../../new_teacher_design/BackPainTherapy/svg';
import { DownArrowIcon } from '../../new_teacher_design/assets/svg/popup_icons';
import Modal from '../../new_teacher_design/BackPainTherapy/CommonComponents/Modal';
import Loader from '../../new_teacher_design/components/FixedComponent/Loader';
import Header from '../util_components/header';

const timeSlotsValues = [
  { value: '5:00 AM', label: '5 AM' },
  { value: '5:30 AM', label: '5:30 AM' },
  { value: '6:00 AM', label: '6 AM' },
  { value: '6:30 AM', label: '6:30 AM' },
  { value: '7:00 AM', label: '7 AM' },
  { value: '7:30 AM', label: '7:30 AM' },
  { value: '8:00 AM', label: '8 AM' },
  { value: '8:30 AM', label: '8:30 AM' },
  { value: '9:00 AM', label: '9 AM' },
  { value: '9:30 AM', label: '9:30 AM' },
  { value: '10:00 AM', label: '10 AM' },
  { value: '10:30 AM', label: '10:30 AM' },
  { value: '11:00 AM', label: '11 AM' },
  { value: '11:30 AM', label: '11:30 AM' },
  { value: '12:00 PM', label: '12 PM' },
  { value: '12:30 PM', label: '12:30 PM' },
  { value: '1:00 PM', label: '1 PM' },
  { value: '1:30 PM', label: '1:30 PM' },
  { value: '2:00 PM', label: '2 PM' },
  { value: '2:30 PM', label: '2:30 PM' },
  { value: '3:00 PM', label: '3 PM' },
  { value: '3:30 PM', label: '3:30 PM' },
  { value: '4:00 PM', label: '4 PM' },
  { value: '4:30 PM', label: '4:30 PM' },
  { value: '5:00 PM', label: '5 PM' },
  { value: '5:30 PM', label: '5:30 PM' },
  { value: '6:00 PM', label: '6 PM' },
  { value: '6:30 PM', label: '6:30 PM' },
  { value: '7:00 PM', label: '7 PM' },
  { value: '7:30 PM', label: '7:30 PM' },
  { value: '8:00 PM', label: '8 PM' },
  { value: '8:30 PM', label: '8:30 PM' },
  { value: '9:00 PM', label: '9 PM' },
  { value: '9:30 PM', label: '9:30 PM' },
  { value: '10:00 PM', label: '10 PM' }
];

const therapistUpdates = [
  {
    id: 1,
    label: 'Prior Coaches',
    bgColor: 'bg-primary'
  },
  {
    id: 2,
    label: 'Any Coaches',
    bgColor: 'bg-lightOrangeBGColor'
  },
  {
    id: 3,
    label: 'No Coaches',
    bgColor: 'bg-orangeLiteColor'
  }
];
const Weeks = [
  {
    id: 1,
    label: 'Mon'
  },
  {
    id: 2,
    label: 'Tue'
  },
  {
    id: 3,
    label: 'Wed'
  },
  {
    id: 4,
    label: 'Thu'
  },
  {
    id: 5,
    label: 'Fri'
  },
  {
    id: 6,
    label: 'Sat'
  },
  {
    id: 7,
    label: 'Sun'
  }
];

function TherapySessionBooking(props) {
  const [time, setTime] = useState([]);
  const [slots, setSlots] = useState({});
  const [selfSlots, setSelfSlots] = useState({});
  const [currentDateRange, setCurrentDateRange] = useState([]);
  const [slotSelected, setSlotSelected] = useState({});
  const [teachersSelected, setTeachersSelected] = useState([]);
  const [toggleSelfAvailaiblity, setToggleSelfAvailaiblity] = useState(false);
  const [booked, setbooked] = useState(false);
  const [bookedSessionDetails, setBookedSessionDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [toggelFailPopup, setToggelFailPopup] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [toggelCofirm, setToggelConfirm] = useState(false);
  const [confirmTeacher, setConfirmTeacher] = useState({});
  const [failReason, setFailReason] = useState('');
  const [timeZone, setTimeZone] = useState('');
  const [noOfDaysToDisplay, setnoOfDaysToDisplay] = useState(window.innerWidth > 564 ? 7 : 4);
  const [slotListMobile, setSlotListMobile] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const student_uuid = urlParams.get('id');
  const redirectFrom = urlParams.get('from');
  const [studentName, setStudentName] = useState('');
  const [studentFullName, setStudentFullName] = useState('');

  const navigate = useNavigate();

  //   const formatData = (response) => {
  //     const convertedObject = {};

  //     response.forEach((day) => {
  //       const dayObject = {};

  //       day.slots.forEach((slot) => {
  //         dayObject[slot.time] = {
  //           epoch_time: slot.epoch_time,
  //           available_teachers: slot.available_teachers
  //         };
  //       });
  //       convertedObject[day.date_v1] = dayObject;
  //     });

  //     return convertedObject;
  //   };

  const formatData = (response, timezone) => {
    const result = {};
    for (const day of response) {
      const dayObject = {};
      for (const timeVal of timeSlotsValues) {
        let timeObject = {
          is_teachers_available: 0,
          epoch_time: null,
          date_val: null,
          available_teachers: [],
          isPreviousTeacher: false
        };
        const index = day?.slots?.findIndex((s) => s.time == timeVal.value);
        if (index != -1) {
          const timeSlot = day.slots[index];
          timeObject = {
            ...timeObject,
            is_teachers_available: 1,
            epoch_time: timeSlot.epoch_time,
            date_val: day.day_full,
            time_selected: timeSlot.time_selected,
            available_teachers: timeSlot.available_teachers,
            isPreviousTeacher:
              timeSlot.available_teachers.filter((t) => t.is_previous_teacher === 1).length > 0
          };
        }
        dayObject[timeVal.value] = timeObject;
      }
      result[day.date_v1] = dayObject;
    }
    return result;
  };

  const renderSessionBookedPopup = () => {
    const epochTime = bookedSessionDetails?.epoch_start_time || '';
    const date = moment(epochTime).tz(timeZone);
    const formattedDate = date.format('dddd Do MMM [at] h:mmA z');

    return (
      <Modal
        onClose={() => {
          setbooked(false);
          navigate(-1);
        }}
      >
        <div className="flex flex-col justify-center items-center zm:px-25px md:px-10 pb-10 pt-1.5">
          <OrangeTick />
          <div className="font-semibold zm:text-19px md:text-19px leading-22px text-greyText zm:mt-10px md:mt-5 text-center flex items-center justify-center">
            You have booked a 1-on-1 session
          </div>
          <div className="font-normal text-14.5px leading-26px text-mytgrey-lite mt-10px">
            for {studentName || ''} with {confirmTeacher?.name || ''} on
          </div>
          <div className="font-medium text-14.5px leading-26px text-blackmt-5px ">
            {formattedDate || ''}
          </div>
          <div className="font-normal text-14.5px leading-26px text-mytgrey-lite mt-5 text-center">
            {' '}
            We just sent an email to {studentName || ''}
          </div>
        </div>
      </Modal>
    );
  };
  const renderConfirmPopup = () => (
    <Modal onClose={() => setToggelConfirm(false)}>
      <div className="items-center zm:px-25px md:px-10 pb-10 pt-1.5">
        <div className="font-bold text-subText md:text-19 zm:text-17">Confirm Booking</div>
        <div style={{ border: '1px solid #e5e7eb', borderRadius: '10px' }} className="my-4">
          <div className="py-4 px-4" style={{ borderBottom: '1px solid #e5e7eb' }}>
            <div className="text-mytgrey-lite">Session date and time</div>
            <div className="font-bold">
              {moment(slotSelected.epoch_time).tz(timeZone).format('ddd, MMM Do, h:mm A z')}
            </div>
          </div>
          <div className="flex flex-row items-center py-4">
            <div className="w-1/2 px-4" style={{ borderRight: '1px solid #e5e7eb' }}>
              <div className="text-mytgrey-lite">Session duration</div>
              <div className="font-bold">60 Minutes</div>
            </div>
            <div className="w-1/2 px-4">
              <div className="text-mytgrey-lite">Teacher</div>
              <div className="font-bold flex flex-row items-center">
                <div className="rounded-full w-25px h-25px mr-10px">
                  <img className="rounded-full" src={confirmTeacher.profile_photo} />
                </div>
                <div>{confirmTeacher.name}</div>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => bookSessionApi()}
          className="btn btn-primary w-full md:text-18.5 zm:text-17 md:py-5 zm:py-3 font-inter font-semibold md:mb-0 zm:mb-6 zm:h-49px lg:h-60px"
        >
          Confirm Booking
        </button>
      </div>
    </Modal>
  );
  const renderFailPopup = () => (
    <Modal
      onClose={() => {
        setToggelFailPopup(false);
        getData();
      }}
    >
      <div className="flex flex-col justify-center items-center zm:px-25px md:px-10 pb-10 pt-1.5">
        <RedCancelIcon />
        <div className="font-semibold text-19px leading-22px mt-15px text-19 text-center">
          {failReason}
        </div>
      </div>
    </Modal>
  );
  const getData = () => {
    const url = urls.therapy_slots;
    setLoading(true);
    const payload = {
      is_yoga_therpaist: 1,
      student_uuid
    };

    post_api(url, payload, true)
      .then((res) => {
        const days = res.data.available_slots;
        // slots.filter((slot) => {
        //     return slot.available_teachers.some(
        //         (teacher) => teacher.uuid === teacherUUID
        //     );
        // });
        const teacherUUID = getCookie('teacher_uuid');
        const filteredDays = [];
        const timezone = res?.data?.student_timezone || 'America/Los_Angeles';
        setTimeZone(timezone);
        setStudentName(res?.data?.student_name);
        setStudentFullName(res?.data?.student_full_name);
        [...res.data.available_slots].forEach((day, index) => {
          const filteredSlots = [];
          day.slots.forEach((slot, index) => {
            let isTeacherThere = false;
            slot.available_teachers.forEach((teacher, index) => {
              if (teacher.uuid === teacherUUID) {
                isTeacherThere = true;
              }
            });
            if (isTeacherThere) {
              filteredSlots.push(slot);
            }
          });
          if (filteredSlots.length > 0) {
            const filteredDay = { ...day, slots: filteredSlots };
            filteredDays.push(filteredDay);
          }
        });
        const selfSlots = formatData(filteredDays, timezone);
        const slotsAvailable = formatData(res.data.available_slots, timezone);
        setSlots(slotsAvailable);
        setSelfSlots(selfSlots);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const toggleSelfAvailibilityFunc = () => {
    setToggleSelfAvailaiblity(!toggleSelfAvailaiblity);
    setTeachersSelected([]);
    setSlotSelected({});
    setShowMore(true);
  };
  useEffect(() => {
    getData();
    sendEventToClarity();
  }, []);

  const convertTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    let convertedHours = parseInt(hours);
    let period = 'AM';

    if (convertedHours >= 12) {
      period = 'PM';
      if (convertedHours > 12) {
        convertedHours -= 12;
      }
    }
    if (minutes?.includes('AM') || minutes?.includes('PM')) {
      return `${convertedHours}:${minutes}`;
    }
    return `${convertedHours}:${minutes} ${period}`;
  };

  const formatTime = (momentTime) => {
    const time_min = momentTime.format('mm');
    const time_min_val = time_min != '00' ? `:${time_min}` : ``;
    return momentTime.format(`h[${time_min_val}]a z`);
  };
  const bookSession = (teacher) => {
    setToggelConfirm(true);
    setConfirmTeacher(teacher);
  };
  const bookSessionApi = () => {
    const url = urls.schedule_session_with_student;
    const payload = {
      target_teacher_uuid: confirmTeacher.uuid,
      start_time: slotSelected.epoch_time,
      student_uuid,
      duration: 60
    };
    setToggelConfirm(false);
    setLoading(true);
    post_api(url, payload, true)
      .then((res) => {
        setLoading(false);
        if (res.data && res.data.scheduled_sessions && res.data.scheduled_sessions.length > 0) {
          setBookedSessionDetails(res.data.scheduled_sessions[0]);
          setbooked(true);
          getData();
        }
      })
      .catch((err) => {
        setLoading(false);
        setToggelFailPopup(true);
        setFailReason(err.response.data.reason || err.response.data.message);
        console.log(err.response.data.reason);
      });
  };
  if (loading) {
    return <Loader />;
  }
  const guessedTimezone = timeZone || 'America/Los_Angeles';
  const currentTime = moment().tz(guessedTimezone);
  const timeZoneIntitals = currentTime.format('z');
  const teacherUUID = getCookie('teacher_uuid');
  let modifiedTeacherList =
    toggleSelfAvailaiblity && teacherUUID
      ? [...teachersSelected].filter((teacher) => teacher.uuid === teacherUUID)
      : [...teachersSelected];
  const showMoreButton = modifiedTeacherList.length > 5;
  const showSlots = !!slotSelected?.epoch_time;
  modifiedTeacherList = showSlots
    ? !showMore
      ? modifiedTeacherList
      : modifiedTeacherList.slice(0, 5)
    : [];
  return (
    <>
      <Header />
      {booked ? (
        renderSessionBookedPopup()
      ) : (
        <div className="bg-backpainBGColor flex justify-center zm:px-25px zm:pt-25px md:pt-80px w-full pb-40px customScrollBar zm:mt-70px lg:mt-0">
          <div className="h-fit zm:w-full md:max-w-980px">
            <div className="flex items-center justify-between">
              <div>
                <div className="flex items-center gap-16px">
                  <div
                    className="text-14px leading-18px font-semibold text-mytpurple cursor-pointer"
                    onClick={() =>
                      props?.router?.location?.state?.profileUrl
                        ? navigate(props?.router?.location?.state?.profileUrl)
                        : null
                    }
                  >
                    {studentName || ''}
                  </div>
                  <div>
                    <BredcrumbIcon />
                  </div>
                  <div className="text-14px leading-18px font-semibold text-mytgrey-lite cursor-pointe">
                    Book a 1-on-1 session
                  </div>
                </div>
                <div className="mt-20px zm:mb-7px md:mb-15px zm:text-19px md:text-38px leading-44px font-extrabold">
                  Book a 1-on-1 session for {studentName}
                </div>
                <div className="leading-18px zm:text-13px md:text-15px font-normal text-mytgrey-contactHeading">
                  This option should be mindfully used in agreement with the client. All sessions
                  are booked for a duration of{' '}
                  <span style={{ fontWeight: 'bold' }}>60 minutes.</span>
                </div>
              </div>
            </div>
            <div className="h-auto w-full flex md:flex-row zm:flex-col zm:gap-20px md:gap-0 zm:mt-34px md:mt-50px">
              <div className="flex justify-center md:p-40px zm:p-20px w-full bg-white border border-stepperPannelBorderColor shadow-borderShadow zm:rounded-8px md:rounded-tr-none md:rounded-br-none">
                <div className="flex flex-col w-full">
                  <div className="flex items-center">
                    <p className="zm:text-17px md:text-19px leading-22px text-mytgrey-subText font-semibold ">
                      Availability of coaches
                    </p>
                    <span className="text-13px font-bold leading-16px ml-5px text-mytgrey-subText">
                      ({timeZoneIntitals || ''})
                    </span>
                  </div>
                  <div className="flex items-center zm:gap-10px md:gap-20px mt-10px mb-20px flex-wrap">
                    {therapistUpdates?.map((items, index) => (
                      <div key={index} className="flex items-center gap-5px">
                        <div className={`h-12px w-12px ${items.bgColor}`} />
                        <div className="text-13.5px font-medium leading-18px text-mytgrey-lite">
                          {items.label}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-col items-center w-max">
                    <RangePicker
                      onDateRangeChange={(dateRange) => {
                        setTeachersSelected([]);
                        setSlotSelected({});
                        setShowMore(true);
                        setCurrentDateRange(dateRange);
                      }}
                      enableDays={noOfDaysToDisplay}
                    />
                    <div className="flex w-full zm:gap-11px md:gap-18px">
                      <div>
                        <div>
                          <ul className="text-mytgrey-lite text-14px font-semibold leading-22px">
                            {timeSlotsValues.map((time, index) => (
                              <li
                                key={time.value}
                                className="flex items-center px-4px"
                                style={{ height: '41px' }}
                              >
                                {time.label}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className="flex">
                            {currentDateRange.map((day, index) => (
                              <div key={index}>
                                {timeSlotsValues.map((time, i) => {
                                  const timeFormatted = convertTime(time.value);
                                  const dayFormatted = moment(day).format('yyyy-MM-DD');

                                  const slotsToPick = toggleSelfAvailaiblity ? selfSlots : slots;

                                  const currentSlot = slotsToPick[day]?.[timeFormatted];
                                  const teachersAvailaible = currentSlot?.available_teachers || [];
                                  const slotPicked =
                                    slotsToPick.hasOwnProperty(dayFormatted) &&
                                    slotsToPick[dayFormatted].hasOwnProperty(timeFormatted)
                                      ? slotsToPick[dayFormatted][timeFormatted]
                                      : {};
                                  const isSlotSelected =
                                    !!slotPicked?.epoch_time &&
                                    slotPicked?.epoch_time == slotSelected.epoch_time;
                                  const isPreviousTeacher = !!slotPicked?.isPreviousTeacher;
                                  const epochTime = slotPicked.epoch_time;
                                  const date = moment(epochTime).tz(
                                    Intl.DateTimeFormat().resolvedOptions().timeZone
                                  );
                                  const formattedDisplayDate = formatTime(date);
                                  return (
                                    <SlotsForTherapy
                                      key={i}
                                      onClick={(teacher) => {
                                        console.log('inside click ', isSlotSelected);
                                        const dateSlot =
                                          slotsToPick.hasOwnProperty(dayFormatted) &&
                                          slotsToPick[dayFormatted].hasOwnProperty(timeFormatted)
                                            ? slotsToPick[dayFormatted][timeFormatted]
                                            : {};
                                        if (isSlotSelected) {
                                          setSlotSelected({});
                                          setShowMore(true);
                                        } else {
                                          setSlotSelected(dateSlot);
                                          setShowMore(true);
                                          setTeachersSelected(teachersAvailaible);
                                          setSlotListMobile(true);
                                        }
                                      }}
                                      state={
                                        teachersAvailaible.length === 0
                                          ? 'no_coaches'
                                          : teachersAvailaible.length > 0 && toggleSelfAvailaiblity
                                            ? 'self_availibility'
                                            : isPreviousTeacher
                                              ? 'prior_coaches'
                                              : 'any_coaches'
                                      }
                                      customClass="hover:bg-mytgreen cursor-pointer"
                                      isSelected={isSlotSelected}
                                      slotPicked={slotPicked}
                                      teachersList={teachersAvailaible}
                                      displayTime={formattedDisplayDate}
                                      toggleSelfAvailaiblity={toggleSelfAvailaiblity}
                                    />
                                  );
                                })}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  teachersSelected.length > 0
                    ? `md:max-w-350px zm:w-full md:h-auto zm:fixed md:static zm:left-0 zm:bottom-0 zm:bg-mytHeaderBg md:bg-transparent zm:h-screen zm:flex zm:items-end md:block zm:pt-60px md:pt-0 zm:z-60 md:z-0 ${
                        slotListMobile ? 'zm:block' : 'zm:hidden'
                      }`
                    : 'md:max-w-350px zm:w-full zm:hidden md:block'
                }`}
              >
                <div
                  className={`relative w-full md:bg-rightPannelBGColor border md:border-rightPannelBorderColor zm:rounded-tl-3xl zm:rounded-tr-3xl md:rounded-bl-none md:rounded-tl-none md:rounded-tr-8px md:h-full md:pt-110px overflow-scroll zm:bg-white ${
                    teachersSelected.length > 0 ? 'zm:h-calc140px zm:p-30px ' : ''
                  }`}
                >
                  <div
                    className="absolute top-20px right-20px zm:block md:hidden"
                    onClick={() => setSlotListMobile(false)}
                  >
                    <CloseIcon />
                  </div>
                  {showSlots && teachersSelected.length > 0 && (
                    <div className="md:text-lightorange zm:text-black zm:text-17px zm:font-bold md:font-medium zm:leading-normal text-left font-medium md:text-12px font-Inter mb-40px">
                      List of available coaches at {slotSelected.time_selected || ''}
                    </div>
                  )}
                  {[...modifiedTeacherList].map((teacher, index) => (
                    <div className="flex mb-40px">
                      <div className="rounded-full w-50px h-50px mr-10px">
                        <img className="rounded-full" src={teacher?.profile_photo} />
                      </div>
                      <div className="flex flex-col justify-center gap-20px">
                        <div className="flex items-center mt-14px text-19px font-medium leading-23px">
                          {teacher?.name || ''}
                        </div>
                        <button className="btn btn-orange" onClick={() => bookSession(teacher)}>
                          Book a session
                        </button>
                      </div>
                    </div>
                  ))}
                  {showSlots && showMore && showMoreButton ? (
                    <button className="btn btn-white px-12" onClick={() => setShowMore(false)}>
                      show more coaches
                      <div style={{ marginLeft: '10px' }}>
                        <DownArrowIcon />
                      </div>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {toggelFailPopup ? renderFailPopup() : null}
      {toggelCofirm ? renderConfirmPopup() : null}
    </>
  );
}

export default TherapySessionBooking;
