import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import SingleRenderedSession from './single_session';
import * as selectors from '../../../redux/selectors';
import * as actions from '../../../redux/action_creators';
import './dashboard_funcs.css';
import { SMSList } from './sms_list';
import Spinner from '../../util_components/spinner';
import { RefreshIconV2 } from '../../../../svg';

class DashboardFuncs extends PureComponent {
  state = {
    session_index_selected: 0,
    modalChat: false,
    nextColumn: 4
  };

  handleMoreButton = () => {
    this.setState({
      nextColumn: this.state.nextColumn + 4
    });
  };

  render_sessions = (item, index, sessions) => {
    // let isLast = index + 1 === session.length;
    const isLast = index === sessions.length - 1;
    return (
      <div key={item.session_uuid} id={index}>
        <SingleRenderedSession
          index={index}
          item={item}
          isLast={isLast}
          is_community_class_proposal_pending={item.is_community_class_proposal_pending}
        />
      </div>
    );
  };

  handleRefreshSessions = () => {
    this.props.load_short_dashboard_sessions();
  };

  render() {
    const { sessions } = this.props;
    if (this.props.session_list_status === 'loading') {
      return (
        <div className="home-content h-full w-full">
          <div className="home-loading">
            <Spinner />
            <p>Loading...</p>
          </div>
        </div>
      );
    }

    if (sessions.length > 0) {
      return (
        <div>
          <div className="flex items-center justify-between mb-30px">
            <p className="zm:text-25px md:text-30px zm:leading-36px font-extrabold leading-36px text-black">
              My sessions
            </p>
            <button
              className="btn btn-white px-14px pt-11.5px pb-10.5px text-13.5px font-semibold leading-normal sm:block zm:hidden"
              onClick={this.handleRefreshSessions}
            >
              Refresh your sessions
            </button>
            <button
              className="btn btn-white text-13.5px font-semibold leading-normal py-9px px-14px sm:hidden zm:block"
              onClick={this.handleRefreshSessions}
            >
              <RefreshIconV2 />
            </button>
          </div>
          <div className="dashboard-sessions-list">
            {/* {sessions.slice(0, this.state.nextColumn)?.map(this.render_sessions)} */}
            {sessions
              .slice(0, this.state.nextColumn)
              ?.map((item, index) => this.render_sessions(item, index, sessions))}
            {this.state.nextColumn >= sessions.length ? null : (
              <div
                className="text-mytblack md:w-auto zm:w-full w-auto zm:mt-25px md:mt-40px btn-white-default font-semibold text-15 relative flex items-center bg-mytgray hover:bg-mytgrey-hover lg:mb-40px"
                onClick={this.handleMoreButton}
              >
                Load More
              </div>
            )}
            {this.props.loadMoreSessions !== undefined &&
              this.props.loadMoreSessions.trim() !== '' && (
                <div
                  className="load-more-sessions"
                  onClick={() => {
                    this.props.load_dashboard_sessions();
                  }}
                >
                  {this.props.loadMoreSessions}
                </div>
              )}
            <div className="zm:flex flex-col lg:hidden mt-30px">
              <SMSList />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  dashboard_loading: selectors.get_dashboard_loading(state),
  sessions_in_next_24_hours: selectors.get_sessions_in_next_24_hours(state),
  availability_in_next_7_days: selectors.get_availability_in_next_7_days(state),
  sessions: selectors.get_sessions(state),
  loadMoreSessions: state.dashboard.loadMoreSessions,
  teacher_token: selectors.get_teacher_token(state),
  profile_basic_details: selectors.basic_details_selector(state),
  teacher_details: state.profile.teacher_details,
  account_created: state.home.account_created,
  create_group_session: state.home.create_group_session,
  session_list_status: state.dashboardv1.session_list_status
});

const mapDispatchToProps = (dispatch) => ({
  cancel_session: (payload) => {
    dispatch(actions.cancel_session(payload));
  },
  load_my_sessions: () => {
    dispatch(actions.load_my_sessions());
  },
  load_dashboard_sessions: () => {
    dispatch(actions.load_dashboard_sessions());
  },
  load_short_dashboard_sessions: () => {
    dispatch(actions.load_short_dashboard_sessions());
  }
});

const DashboardFuncsWithRedux = connect(mapStateToProps, mapDispatchToProps)(DashboardFuncs);

export default DashboardFuncsWithRedux;
